<template>
    <img v-show="imgObj.imgLoaded" :src="imgObj.imageSrc" alt="" @load="imgLoadedHandle(imgObj)" @error="imgErrorHandle($event.target)">
    <img v-if="!imgObj.imgLoaded" src="https://artlib.cn/assets/artlib2023/loading.png" alt="">
</template>

<script setup>
import {onMounted, ref, watch} from "vue"

// 获取 props 参数
const props = defineProps(["image"])

let imgObj = ref({
    imageSrc: props.image,
    imgLoaded: false
})

// 图片加载完成
function imgLoadedHandle(item) {
    item.imgLoaded = true;
}

// 图片加载失败
function imgErrorHandle(dom) {
    dom.src='https://artlib.cn/assets/artlib2023/default.png'
}

onMounted(()=>{
    
})

watch(
  () => props.image,
  (newVal, oldVal) => {
    imgObj.value.imgLoaded = false;
    imgObj.value.imageSrc = newVal;
  }
)
</script>

<style lang="scss" scoped>
img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    transition: All 0.5s ease-in-out;
    &:hover {
        transform: scale(1.08);
    }
}
</style>