<template>
    <div>
        <div class="data-list">
            <div class="item-view"
                v-for="(item, index) in stories" 
                :key="index"
                :class="'item-'+index"
                :style="{'z-index': item.zIndex, 'transform': item.scale}"
                @mouseover="mouseOver(index, $event)" @click="toStoryDetail(item.id)">

                <!-- 内容 -->
                <div class="work-layer" v-if="item.layer"></div>

                <div class="work-img">
                    <img :src="'https://artlib.cn/'+item.img_cover" alt="">
                </div>
                <div class="info-view">
                    <div class="work-title">{{ item.title }}</div>
                </div>
            </div>
        </div>
    </div>
</template>



<script setup>
import { getReq, getReqWithToken, postReq, postReqWithToken} from "@/api"
import { onMounted, ref } from "vue"
import router from "@/router"

let works = ref([]);
let artists = ref([]);
let museums = ref([]);
let stories = ref([]);
let specials = ref([]);

let works_first = ref({});
let artists_first = ref({});
let museums_first = ref({});
let stories_first = ref({});
let specials_first = ref({});

// default config
// let collection = ref([
//     {zIndex: 3, scale: 'scale(0.8)'},
//     {zIndex: 4, scale: 'scale(0.9)'},
//     {zIndex: 5, scale: 'scale(1)'},
//     {zIndex: 2, scale: 'scale(0.7)'},
//     {zIndex: 1, scale: 'scale(0.6)'},
// ]);

onMounted(()=>{
    getHomeData();
})


// 获取数据
function getHomeData() {
    getReq(`/home`).then(ret=>{
        let resp = ret.data.data;
        works.value = resp.works;
        artists.value = resp.artists;
        museums.value = resp.museums;
        stories.value = resp.stories.slice(0, 5);
        specials.value = resp.specials;

        works_first.value = resp.works[0];
        artists_first.value = resp.artists[0];
        museums_first.value = resp.museums[0];
        stories_first.value = resp.stories[0];
        specials_first.value = resp.specials[0];

        // default config.
        stories.value[0].zIndex = 3;
        stories.value[1].zIndex = 4;
        stories.value[2].zIndex = 5;
        stories.value[3].zIndex = 2;
        stories.value[4].zIndex = 1;

        stories.value[0].scale = 'scale(0.8)';
        stories.value[1].scale = 'scale(0.9)';
        stories.value[2].scale = 'scale(1)';
        stories.value[3].scale = 'scale(0.9)';
        stories.value[4].scale = 'scale(0.8)';

        stories.value[0].layer = true;
        stories.value[1].layer = true;
        stories.value[2].layer = false;
        stories.value[3].layer = true;
        stories.value[4].layer = true;
    })
}


function mouseOver(index, event) {
    let collection = stories;

    collection.value[index].zIndex = 5;
    switch(index) {
        case 0:
            collection.value[1].zIndex = 4;
            collection.value[2].zIndex = 3;
            collection.value[3].zIndex = 2;
            collection.value[4].zIndex = 1;


            collection.value[0].scale = 'scale(1)';
            collection.value[1].scale = 'scale(0.9)';
            collection.value[2].scale = 'scale(0.8)';
            collection.value[3].scale = 'scale(0.7)';
            collection.value[4].scale = 'scale(0.6)';

            collection.value[0].layer = false;
            collection.value[1].layer = true;
            collection.value[2].layer = true;
            collection.value[3].layer = true;
            collection.value[4].layer = true;
            break;
        case 1:
            collection.value[0].zIndex = 4;
            collection.value[2].zIndex = 3;
            collection.value[3].zIndex = 2;
            collection.value[4].zIndex = 1;

            collection.value[0].scale = 'scale(0.8)';
            collection.value[1].scale = 'scale(1)';
            collection.value[2].scale = 'scale(0.8)';
            collection.value[3].scale = 'scale(0.7)';
            collection.value[4].scale = 'scale(0.6)';

            collection.value[0].layer = true;
            collection.value[1].layer = false;
            collection.value[2].layer = true;
            collection.value[3].layer = true;
            collection.value[4].layer = true;
            break;
        case 2:
            collection.value[0].zIndex = 3;
            collection.value[1].zIndex = 4;
            collection.value[3].zIndex = 2;
            collection.value[4].zIndex = 1;

            collection.value[0].scale = 'scale(0.8)';
            collection.value[1].scale = 'scale(0.9)';
            collection.value[2].scale = 'scale(1)';
            collection.value[3].scale = 'scale(0.9)';
            collection.value[4].scale = 'scale(0.8)';

            collection.value[0].layer = true;
            collection.value[1].layer = true;
            collection.value[2].layer = false;
            collection.value[3].layer = true;
            collection.value[4].layer = true;
            break;
        case 3:
            collection.value[0].zIndex = 2;
            collection.value[1].zIndex = 3;
            collection.value[2].zIndex = 4;
            collection.value[4].zIndex = 1;

            collection.value[0].scale = 'scale(0.7)';
            collection.value[1].scale = 'scale(0.8)';
            collection.value[2].scale = 'scale(0.9)';
            collection.value[3].scale = 'scale(1)';
            collection.value[4].scale = 'scale(0.9)';

            collection.value[0].layer = true;
            collection.value[1].layer = true;
            collection.value[2].layer = true;
            collection.value[3].layer = false;
            collection.value[4].layer = true;
            break;
        case 4:
            collection.value[0].zIndex = 1;
            collection.value[1].zIndex = 2;
            collection.value[2].zIndex = 3;
            collection.value[3].zIndex = 4;

            collection.value[0].scale = 'scale(0.6)';
            collection.value[1].scale = 'scale(0.7)';
            collection.value[2].scale = 'scale(0.8)';
            collection.value[3].scale = 'scale(0.9)';
            collection.value[4].scale = 'scale(1)';

            collection.value[0].layer = true;
            collection.value[1].layer = true;
            collection.value[2].layer = true;
            collection.value[3].layer = true;
            collection.value[4].layer = false;
            break;
    }
}


function mouseOut(e) {
    
}


// 到艺术家详细信息页面
function toArtistDetail(id) {
    const { href } = router.resolve({
        path: "/artistDetail",
        query: {
            id: id
        }
    });
    window.open(href, '_blank');
}

// 到艺术品详细信息页面
function toArtworkDetail(id) {
    const { href } = router.resolve({
            path: "/artworkFullScreen",
            query: {
                id: id
            }
        });
    window.open(href, '_blank');
}

// 跳转到艺术机构详情页面
function toMuseumDetail(id) {
    router.push({
        path: '/museumDetail',
        query: {
            id: id
        }
    })
}

// 跳转到艺术故事详情页面
function toStoryDetail(id) {
    const { href } = router.resolve({
        path: "/storyDetail",
        query: {
            id: id
        }
    });
    window.open(href, '_blank');
}

// 跳转到艺术专题详情页面
function toSpecialDetail(id) {
    const { href } = router.resolve({
        path: "/specialDetail",
        query: {
            id: id
        }
    });
    window.open(href, '_blank');
}
</script>

<style lang="scss" scoped>

.data-list {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .item-view {
        min-width: 420px;
        width: 420px;
        height: 450px;
        color: white;
        font-size: 30px;
        border-radius: 10px;
        overflow: hidden;
        position: relative;

        // transition: all 0.2s;
        transition: transform 500ms cubic-bezier(0.19,1,0.22,1);
        cursor: pointer;

        .work-layer {
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            position: absolute;
            top: 0;
            left: 0;
        }

        .work-img {
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .info-view {
            width: 90%;

            position: absolute;
            bottom: 10px;
            left: 10px;
            color: white;

            .work-title {
                font-size: 18px;
            }

            .work-author {
                font-size: 14px;
            }
        }
    }
    .item-view:not(:first-child) {
        margin-left: -225px;
    }
    .item-view::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow: inset 0px -100px 45px -27px rgba(0,0,0,0.7);
    }

    .item-0 {
        background-color: red;
    }
    .item-1 {
        background-color: green;
    }
    .item-2 {
        background-color: darkblue;
    }
    .item-3 {
        background-color: blueviolet;
    }
    .item-4 {
        background-color: slateblue;
    }
}
</style>
