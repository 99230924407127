<template>
    <!-- 控制主题 -->
    <lay-config-provider :theme="theme" :themeVariable="themeVariable">    
    </lay-config-provider>

    <!-- 抽屉 -->
    <lay-layer type="drawer" 
    v-model="visible" 
    title="菜单" 
    offset="l" 
    area="300px" 
    shadeOpacity="0.01">
      <div class="menus">
        <div class="menu-item" @click="router.push('/'); visible=false;">首页</div>
        <div class="menu-item" @click="router.push('/artist'); visible=false;">艺术家</div>
        <div class="menu-item" @click="router.push('/artwork'); visible=false;">艺术品</div>
        <div class="menu-item" @click="router.push('/museum'); visible=false;">艺术机构</div>
        <div class="menu-item" @click="router.push('/story'); visible=false;">艺术故事</div>
        <div class="menu-item" @click="router.push('/special'); visible=false;">艺术专题</div>
        <hr>
        <div class="menu-item" @click="router.push('/search'); visible=false;">搜索</div>
        <hr>
        <div class="menu-item" @click="router.push('/activity/list'); visible=false;">活动</div>
        <div class="menu-item" @click="router.push('/exhibition/list'); visible=false;">3D展览</div>
        <hr>
        <div class="menu-item" @click="router.push('/profile/personal'); visible=false;">个人中心</div>
        <div class="menu-item" @click="router.push('/login'); visible=false;">登录页</div>
      </div>
    </lay-layer>

    <!-- 导航栏 -->
    <div class="navbar">
        <div class="left-view">
            <div class="menu-view">
                <div class="item menu-icon-view" @click="changeVisible">
                    <svg t="1684833367952" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2394" width="32" height="32"><path d="M192.662463 640.429021l637.429711 0 0 70.827115-637.429711 0 0-70.827115Z" fill="#020202" p-id="2395"></path><path d="M192.662463 446.99345l637.429711 0 0 70.825069-637.429711 0 0-70.825069Z" fill="#020202" p-id="2396"></path><path d="M192.662463 256.658498l637.429711 0 0 70.825069-637.429711 0 0-70.825069Z" fill="#020202" p-id="2397"></path></svg>
                </div>

                <div class="item logo-view" @click="router.push('/')">
                    Artlib 世界艺术鉴赏库
                </div>
            </div>
        </div>
        <div class="right-view">
            <div class="menu-view">
                <div :class="{'menuActive': activeMenuItem == index, 'item': true}" v-for="(menu, index) in menuList" :key="index" 
                @click="changeMenuActive(index)">{{menu.name}}</div>


                <div class="search-input-view" @click="router.push('/search')">
                    <svg style="margin-top: 10px;" width="24" height="24" viewBox="0 0 48 48" focusable="false" class=""><path d="M31 28h-1.59l-.55-.55C30.82 25.18 32 22.23 32 19c0-7.18-5.82-13-13-13S6 11.82 6 19s5.82 13 13 13c3.23 0 6.18-1.18 8.45-3.13l.55.55V31l10 9.98L40.98 38 31 28zm-12 0c-4.97 0-9-4.03-9-9s4.03-9 9-9 9 4.03 9 9-4.03 9-9 9z"></path></svg>
                </div>


                <div class="item" v-if="showRecharge" @click="router.push('/recharge')">会员</div>
                <div class="item" v-if="!loginName" @click="router.push('/login')">登录</div>

                {{ loginName }}
                <div class="item loginName" @click="toProfile">
                    <div class="user-name-view">
                        {{ getFristCharFromString(loginName) }}
                    </div>

                    <div class="profile-menu">
                        <div class="menu-item" v-if="!showRecharge" @click="router.push('/profile/organization')">用户中心</div>
                        <div class="menu-item" v-if="showRecharge" @click="router.push('/profile/personal')">用户中心</div>
                        <div class="menu-item" @click="logout">退出登录</div>
                    </div>
                </div>
                <div class="item">
                    <lay-switch v-model="switchOption" @change="changeSwitch" size='sm'>
                        <template #onswitch-icon>
                            <lay-icon type="layui-icon-circle-dot"></lay-icon>
                        </template>
                        <template #unswitch-icon>
                            <lay-icon type="layui-icon-light"></lay-icon>
                        </template>
                    </lay-switch>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import config from "@/config.js";
import store from "@/store";
import axios from "axios";
import {onMounted, onUpdated, ref} from "vue"
import { layer } from "@layui/layer-vue"

const { default: router }=require("@/router");

const switchOption = ref(false);
let storageTheme = localStorage.getItem('theme');


// 是否显示充值会员，如果是个人用户就显示，如果是机构用户则不需要显示
let showRecharge = ref(false);

// 控制抽屉的显示
const visible = ref(false)
const changeVisible = function() {
    visible.value = !visible.value;
}

// layui-vue 主题色配置
const themeVariable = { 
    "--global-primary-color": "#bfa07e",
    "--global-checked-color": "#bfa07e",
    "--global-checked-color": "#bfa07e"
}

onMounted(()=>{
    if (storageTheme == '' || storageTheme == undefined || storageTheme == null ){
        storageTheme = 'light'
        localStorage.setItem('theme', 'light');
        switchOption.value = false;
    }

    if(localStorage.getItem('theme') == 'dark') {
        switchOption.value = true;
    } else {
        switchOption.value = false;
    }
})

const theme = ref(
    storageTheme
)

// 切换主题
function changeSwitch(val) {
    if(val==true) {
        console.log('set dark')
        localStorage.setItem('theme', 'dark');
        theme.value = localStorage.getItem('theme');
    } else {
        console.log('set light')
        localStorage.setItem('theme', 'light');
        theme.value = localStorage.getItem('theme');
    }
}

// 切换激活的菜单项
function changeMenuActive(index) {
    activeMenuItem.value = index; // 更新选中项

    let path = menuList.value[index].path;
    router.push(path, index);
}


// 退出登录
function logout() {
    localStorage.removeItem('userId');
    localStorage.removeItem('name');
    localStorage.removeItem('token');
    localStorage.removeItem('kind');
    
    window.location = '/login';
}


let loginName = ref();

let activeMenuItem = ref(0);
let menuList = ref([
    {path: '/', name: '首页'},
    {path: '/artist', name: '艺术家'},
    {path: '/artwork', name: '艺术品'},
    {path: '/museum', name: '机构'},
    {path: '/story', name: '故事'},
    {path: '/special', name: '专题'}  
]);

// 是否显示菜单
let showMenu = ref(true);

// 是否显示菜单
function isShowMenu() {
    showMenu.value= !showMenu.value;
}

onMounted(()=>{
    loginName.value = localStorage.getItem('name');

    let kind = localStorage.getItem('kind');
    if (kind == 1) {
        showRecharge.value = true;
    }
})


onUpdated(()=>{
    loginName.value = localStorage.getItem('name');
})


// 跳转到用户中心页面
function toProfile() {
    let kind = localStorage.getItem("kind");
    if( kind== 1) {
        router.push({
            path: '/profile/personal',
        });
    } else if(kind==2) {
        router.push({
            path: '/profile/organization',
        });
    }
}

function getFristCharFromString(text) {
    if(text!=undefined && text !='') {
        return text.split('')[0];
    }
    return text;
}
</script>

<style lang="scss" scoped>
.navbar {
    min-width: 400px;
    width: 100%;
    height: 56px;
    padding: 5px 20px;
    box-sizing: border-box;
    background: white;
    box-shadow: 0 2px 3px rgba(0,0,0,0.1);

    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .left-view {
        .menu-view {
            display: flex;
            flex-direction: row;
            align-items: center;

            width: 100%;
            height: 100%;

            .logo-view {
                cursor: pointer;
                width: 250px;
                padding: 5px;
                box-sizing: border-box;

                display: flex;
                flex-direction: row;
                align-items: center;
                font-size: 18px;
                font-weight: bold;
                font-family: 'Sans' !important; 
            }

            .menu-icon-view {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                margin-right: 20px;
            }
        }
    }

    .right-view {
        .menu-view {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-end;

            .item {
                padding: 10px 0;
                margin: 0 20px;
                cursor: pointer;
            }
        }
    }
}


// @media screen and (min-width: 0px) and (max-width: 1000px) {
//     .navbar {
//         padding: 10px 0;
//         box-sizing: border-box;
//         width: 100%;
//         height: 70px;

//         color: white;
//         background-color: var(--artlibMasterColor);
//         display: flex;
        
//         .left-view {
//             width: 100%;
//             display: block;

//             .menu-view {
//                 display: flex;
//                 flex-direction: row;

//                 .logo-view {
//                     width: 90px;
//                     height: 40px;
//                     box-sizing: border-box;
//                     img {
//                         width: 100%;
//                         height: 100%;
//                         object-fit: cover;
//                     }
//                 }
//             }
//         }

//         .center-view, .right-view {
//             display: none;
//         }
//     }
// }

// 客户名称
.loginName {
    color: var(--artlibMasterColor) !important;
    font-weight: bold;
    position: relative;

    .user-name-view {
        background-color: #8d6e63;
        color: white;
        padding: 10px;

        width: 35px;
        height: 35px;
        border-radius: 50%;
        box-sizing: border-box;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .profile-menu {
        display: none;
    }

    &:hover .profile-menu{
        font-weight: 100;
        border-radius: 5px;
        color: black;
        position: absolute;
        top: 50px;
        width: 80px;
        height: 80px;
        background-color: white;
        display: block;
        padding: 20px;
        box-shadow: 0 0 2px #aaa;

        .menu-item:hover {
            color: var(--artlibMasterColor);
        }
    }
}

// 当前选中的菜单项
.menuActive {
    border-bottom: 3px solid var(--artlibMasterColor) !important;
}

.search-input-view {
    margin-left: 20px;
    cursor: pointer;
    position: relative;

    .search-input {
        box-sizing: border-box;
        cursor: pointer;
        width: 120px;
        height: 35px;
        border-radius: 30px;
        border: 1px solid #ddd;
        padding-left: 35px;
        color: rgba($color: #000000, $alpha: 0.7);
        box-shadow: inset 0 0 10px #cdcdcd;

        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        &:active {
            border: 1px solid #aaa;
        }
    }

    .search-icon {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translate(0, -50%);        
    }
}


// 抽屉
.menus {
  padding: 20px;
  
  display: flex;
  flex-direction: column;

  .menu-item {
    width: 100%;
    height: 50px;
    font-weight: bold;
    cursor: pointer;
    padding: 0 20px;
    color: #333;

    display: flex;
    flex-direction: row;
    align-items: center;
    
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;

    &:hover {
      color: var(--artlibMasterColor);
      background-color: var(--artlibColor2);
    }
  }
}
</style>