<template>
    <!-- 导航栏 -->
    <G_Navbar v-if="store.state.ShowNavbarComponent"></G_Navbar>

    <!-- 渲染其他组件页面 -->
    <div class="page-view">
      <router-view />
    </div>

    <!-- 底部 -->
    <Footer v-if="store.state.ShowFooterComponent"></Footer>
</template>

<script setup>
import Footer from "@/components/Footer"
import G_Navbar from "@/components/G_Navbar.vue"


import {ref} from "vue"
import { useStore } from 'vuex'

const store = useStore()

let ShowFooterComponent = ref(store.state.ShowFooterComponent);
let ShowNavbarComponent = ref(store.state.ShowNavbarComponent);


// 引入客服插件 start
(function(w,d,v3){
w.chaportConfig = {
  appId : '643ccb5b6c58f93be6f64fe2'
};

if(w.chaport)return;v3=w.chaport={};v3._q=[];v3._l={};v3.q=function(){v3._q.push(arguments)};v3.on=function(e,fn){if(!v3._l[e])v3._l[e]=[];v3._l[e].push(fn)};var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://app.chaport.com/javascripts/insert.js';var ss=d.getElementsByTagName('script')[0];ss.parentNode.insertBefore(s,ss)})(window, document);
// 引入客服插件 end.
</script>

<style lang="scss">
@import '@/assets/artlib.scss';

// 所有页面显示导航栏, 用于解决页面切换抖动问题
html {
  overflow-y: scroll;
  overflow-x: hidden;
}

body {
  color: rgb(32,33,36);
  background-color: white;
  // font-family: 'Sans' !important;
}

.container-view {
  background-color: white;
  margin: 120px auto !important;
  border-radius: 5px;
  padding: 0 20px;
}

// 组件渲染页面
.page-view {
  margin-top: 56px !important;
}

// 分页控件
.page-box-view {
  margin-top: 70px !important;

  display: flex;
  flex-direction: row;
  justify-content: center;
}

// 页面小于1000px的时候
@media screen and (min-width: 0px) and (max-width: 1000px){
  .page-view {
    margin-top: 70px !important;
    overflow:hidden;
  }
}

// 搜索框
.search-view {
  margin: 10px 0;
}

// 去搜索页面样式
.to-search-view {
  cursor: pointer;
}

// 设置全局字体
* {
  font-family: "Microsoft YaHei";
}

// 浮动菜单
.float-menu {
  width: 40px;
  height: 120px;
  box-sizing: border-box;
  padding: 5px;
  background-color: rgba($color: #000000, $alpha: 0.6);
  border: 1px solid #aaa;
  border-radius: 10px;
  position: fixed;
  right: 40px;
  bottom: 10%;
  z-index: 10000;

  .float-menu-item {
    margin: 15px 0;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}


// 加载中
.loading-view {
  width: 100px;
  height: 100px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  img {
    width: 100%;
    height: 100%;
  }
}

// 居中标题
.center-title {
  text-align: center;
  margin: 20px 0;
  font-size: 3em;
  font-weight: 900;
  font-family: 微软雅黑;
  color: rgba($color: #000000, $alpha: 0.8);
}


// 卡片-选项卡
.tabs {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.tabs ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.tabs li {
  padding: 8px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  border-bottom-color: transparent;
  transition: border-bottom-color 0.3s;
}

.tabs li:hover {
  border-bottom-color: black;
}

.tabs li.selected {
  border-bottom-color: black;
}

.tab-content {
  padding: 16px;
}




// 输入框样式
.form-input {
    width: 100%;
    height: 35px;
    border: 1px solid #ddd;
    padding: 2px 10px;
    box-sizing: border-box;

    &:focus {
        outline: none;
        border: 1px solid #aaa;
    }
}

.form-button {
  width: 100px;
  height: 35px;
  border: none;
  background-color: #eee;
  cursor: pointer;

  &:hover {
    background-color: #ddd;
  }
  &:active {
    background-color: #eee;
  }
}

.form-button-full {
  width: 100%;
  height: 35px;
  border: none;
  background-color: #eee;
  cursor: pointer;

  &:hover {
    background-color: #ddd;
  }
  &:active {
    background-color: #eee;
  }
}

// 浅色文本
.light-text {
  color: rgba($color: #000000, $alpha: 0.6) !important;
}


// 选项卡居中
.layui-tab-title {
  text-align: center !important;
}


// label
.label {
  color: white !important;
  background-color: var(--artlibMasterColor) !important;
  padding: 0 10px !important;
  border-radius: 20px !important;
}


// 头部标题
.header-title-view {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 0;

    .left {
        font-size: 18px;
        font-weight: bold;
        color: #3c4043;
    }

    .right {
        color: var(--artlibMasterColor);
        cursor: pointer;
    }
}

// 头部标题
.header-title {
    font-size: 18px;
    font-weight: bold;
    color: #3c4043;
}





// 设置默认分页控件样式
.el-pager .is-active {
  background-color: var(--artlibMasterColor) !important;
}
.el-pager .number:hover {
  color: var(--artlibMasterColor) !important;
}
.el-pager .number:active {
  color: white !important;
}

// 上一页，下一页按钮
.btn-next, .btn-prev {
  width: 100px !important;
  height: 50px !important;
}
.btn-next:hover, .btn-prev:hover {
  color: var(--artlibMasterColor) !important;
}
.btn-quicknext:hover, .btn-quickprev:hover {
  color: var(--artlibMasterColor) !important;
}
.btn-quicknext,.btn-quickprev,.el-pager .number {
  width: 50px !important;
  height: 50px !important;
  font-size: 16px !important;
}
.el-pagination__jump .el-input--small{
  width: 50px !important;
  height: 50px !important;
  font-size: 16px !important;
}
.el-pagination__jump, .el-input__wrapper, .el-input__inner{
  z-index: 1 !important;
}

// 设置当前激活页样式
.el-pager .is-active {
  color: white !important;
  &:active {
    color: white !important;
  }
  &:hover {
    color: white !important;
  }
  &:focus {
    color: white !important;
  }
}










// 公共边距样式
.space-mh50 {
  margin: 50px auto !important;
}
.space-mh20 {
  margin: 20px auto !important;
}
.space-mh10 {
  margin: 10px auto !important;
}
.space-mh5 {
  margin: 5px auto !important;
}

.space-mht50 {
  margin-top: 50px !important;
}
.space-mht20 {
  margin-top: 20px !important;
}
.space-mht10 {
  margin-top: 10px !important;
}
.space-mht5 {
  margin-top: 5px !important;
}


// 高亮显示的字段
.light-field {
  color: var(--artlibMasterColor) !important;
  border: 1px solid var(--artlibMasterColor);
  padding: 2px 10px;
  border-radius: 10px;
  margin: 2px 2px;
}


// 设置手型
.cursor-pointer {
  cursor: pointer !important;
}


/* 引入自定义字体 */
@font-face {
  font-family: 'Sans';
  src: url('/public/fonts/GoogleSansText-Regular.ttf');
}
</style>