<template>
    <div class="container-view">
        <lay-row space="20">
            <!-- 左 -->
            <lay-col sm="8">
                <div>
                    <lay-col sm="24">
                        <div class="left-view item-view" @click="toArtworkDetail(works_first.id)">
                            <!-- 艺术品 -->
                            <div class="img-view">
                                <!-- <ArtImg image="https://artlib.cn:443/upload2/files/2019/9/29/201909291438458wWOW5GG.jpg"/> -->
                                <ArtImgLayer :image="'https://artlib.cn/'+works_first.imgs"/>
                            </div>
                            <div class="info-view">
                                <div class="label-view">
                                    <span class="label-item">艺术品</span>
                                </div>

                                <div class="name-view space-mh5">
                                    <span>{{works_first.name}}</span>
                                </div>
                                <div class="space-mh5">
                                    <span>{{works_first.imp_author_name}}</span>
                                </div>
                            </div>
                        </div>
                    </lay-col>
                </div>
            </lay-col>

            <!-- 右 -->
            <lay-col sm="16">
                <div>
                    <!-- 上 -->
                    <lay-row space="20">
                        <lay-col sm="15">
                            <div class="right-top-left-view item-view" @click="toArtistDetail(artists_first.id)">
                                <!-- 艺术家 -->
                                <div class="img-view">
                                    <!-- <ArtImg image="https://artlib.cn:443/upload2/files/2019/9/29/201909291438458wWOW5GG.jpg"/> -->
                                    <ArtImgLayer :image="'https://artlib.cn/'+artists_first.img"/>
                                </div>

                                <div class="info-view">
                                    <div class="label-view">
                                        <span class="label-item">艺术家</span>
                                    </div>

                                    <div class="name-view space-mh5">
                                        <span>{{ artists_first.name }}</span>
                                    </div>
                                </div>
                            </div>
                        </lay-col>

                        <lay-col sm="9">
                            <div class="right-top-right-view item-view" @click="toMuseumDetail(museums_first.id)">
                                <div class="img-view">
                                    <!-- <ArtImg image="https://artlib.cn:443/upload2/files/2019/9/29/201909291438458wWOW5GG.jpg"/> -->
                                    <ArtImgLayer :image="'https://artlib.cn/'+museums_first.img_cover"/>
                                </div>

                                <div class="info-view">
                                    <div class="label-view">
                                        <span class="label-item">艺术机构</span>
                                    </div>

                                    <div class="name-view space-mh5">
                                        <span>{{ museums_first.name }}</span>
                                    </div>
                                </div>
                            </div>
                        </lay-col>
                    </lay-row>
                    

                    <!-- 下 -->
                    <lay-row space="20">
                        <lay-col sm="9">
                            <div class="right-bottom-left-view item-view" @click="toStoryDetail(stories_first.id)">
                                <div class="img-view">
                                    <!-- <ArtImg image="https://artlib.cn:443/upload2/files/2019/9/29/201909291438458wWOW5GG.jpg"/> -->
                                    <ArtImgLayer :image="'https://artlib.cn/'+stories_first.img_cover"/>
                                </div>

                                <div class="info-view">
                                    <div class="label-view">
                                        <span class="label-item">艺术故事</span>
                                    </div>

                                    <div class="name-view space-mh5">
                                        <span>{{stories_first.title}}</span>
                                    </div>
                                </div>
                            </div>
                        </lay-col>

                        <lay-col sm="15">
                            <div class="right-bottom-right-view item-view" @click="toSpecialDetail(specials_first.id)">
                                <div class="img-view">
                                    <!-- <ArtImg image="https://artlib.cn:443/upload2/files/2019/9/29/201909291438458wWOW5GG.jpg"/> -->
                                    <ArtImgLayer :image="'https://artlib.cn/'+specials_first.imgs"/>
                                </div>

                                <div class="info-view">
                                    <div class="label-view">
                                        <span class="label-item">艺术专题</span>
                                    </div>

                                    <div class="name-view space-mh5">
                                        <span>{{specials_first.title}}</span>
                                    </div>
                                </div>
                            </div>
                        </lay-col>
                    </lay-row>
                </div>
            </lay-col>
        </lay-row>
    </div>
</template>

<script setup>
import ArtImgLayer from "@/components/ArtImgLayer.vue"
import router from "@/router"
import { getReq, getReqWithToken, postReq, postReqWithToken} from "@/api"
import { onMounted, ref } from "vue"

let works = ref([]);
let artists = ref([]);
let museums = ref([]);
let stories = ref([]);
let specials = ref([]);

let works_first = ref({});
let artists_first = ref({});
let museums_first = ref({});
let stories_first = ref({});
let specials_first = ref({});

onMounted(()=>{
    getHomeData();
})


// 获取数据
function getHomeData() {
    getReq(`/home`).then(ret=>{
        let resp = ret.data.data;
        works.value = resp.works;
        artists.value = resp.artists;
        museums.value = resp.museums;
        stories.value = resp.stories;
        specials.value = resp.specials;

        works_first.value = resp.works[0];
        artists_first.value = resp.artists[0];
        museums_first.value = resp.museums[0];
        stories_first.value = resp.stories[0];
        specials_first.value = resp.specials[0];
    })
}


// 到艺术家详细信息页面
function toArtistDetail(id) {
    const { href } = router.resolve({
        path: "/artistDetail",
        query: {
            id: id
        }
    });
    window.open(href, '_blank');
}

// 到艺术品详细信息页面
function toArtworkDetail(id) {
    const { href } = router.resolve({
            path: "/artworkFullScreen",
            query: {
                id: id
            }
        });
    window.open(href, '_blank');
}

// 跳转到艺术机构详情页面
function toMuseumDetail(id) {
    router.push({
        path: '/museumDetail',
        query: {
            id: id
        }
    })
}

// 跳转到艺术故事详情页面
function toStoryDetail(id) {
    const { href } = router.resolve({
        path: "/storyDetail",
        query: {
            id: id
        }
    });
    window.open(href, '_blank');
}

// 跳转到艺术专题详情页面
function toSpecialDetail(id) {
    const { href } = router.resolve({
        path: "/specialDetail",
        query: {
            id: id
        }
    });
    window.open(href, '_blank');
}
</script>

<style lang="scss" scoped>
.container-view {
    max-width: 1200px;

    .left-view {
        height: 520px; border-radius: 10px; overflow: hidden;
    }

    .right-top-left-view {
        height: 252px; border-radius: 10px; overflow: hidden;
    }

    .right-top-right-view {
        height: 252px; border-radius: 10px; overflow: hidden;
    }

    .right-bottom-left-view {
        height: 252px; border-radius: 10px; overflow: hidden;
    }

    .right-bottom-right-view {
        height: 252px; border-radius: 10px; overflow: hidden;
    }
}

.img-view {
    width: 100%;
    height: 100%;
    background-color: #ddd;
}

.item-view {
    cursor: pointer;
    position: relative;

    .info-view {
        // 禁止鼠标事件：防止影响 :hover 效果
        pointer-events: none;
        
        color: white;
        position: absolute;       
        bottom: 10px;
        left: 10px;
        z-index: 100;

        .label-view {
            .label-item {
                background-color: var(--artlibMasterColor);
                padding: 2px 5px;
                border-radius: 4px;
            }
        }

        .name-view {
            font-size: 18px;
        }
    }
}
</style>