import axios from 'axios'
import config from "@/config.js"
import { layer } from  "@layui/layer-vue"
import router from "@/router"

let baseURL = config.baseUrl;

// 创建自定义axios实例
const instance = axios.create({
    baseURL, // 请求路径
    timeout: 20000, // 超时时间
})


// 封装get方法
/**
 * @params  url
 * @params  params 要传递的数据 必须有默认值
 * 返回值:返回promise对象
*/
export function getReqWithToken(url, params={}){
    /**
     * resolve:执行成功的方法
     * reject:执行失败的方法
    */
   
    params.headers = {
        'token': localStorage.getItem('token'),
    }

   return new Promise((resolve,reject)=>{
        instance.get(url, params).then(res=>{
            // 服务端响应成功结果
            if(res.data.code == 401) {
                layer.notifiy({
                    title: "错误",
                    content: "身份过期，请重新登录！",
                    icon: 2,
                    time: 3000,
                })

                // 身份过期跳转到登录页面
                router.push('/login');
            }

            resolve(res)
        }).catch(err=>{
            layer.notifiy({
                title: "Network 错误",
                content: err.message,
                icon: 2,
                time: 5000,
            })
            reject(err)
        })
   })
}


// 封装get方法
/**
 * @params  url
 * @params  params 要传递的数据 必须有默认值
 * 返回值:返回promise对象
*/
export function getReq(url, params = null){
    /**
     * resolve:执行成功的方法
     * reject:执行失败的方法
    */
   return new Promise((resolve,reject)=>{
        instance.get(url,params).then(res=>{
            // 服务端响应成功结果
            if(res.data.code == 401) {
                layer.notifiy({
                    title: "错误",
                    content: "身份过期，请重新登录！",
                    icon: 2,
                    time: 3000,
                })

                // 身份过期跳转到登录页面
                router.push('/login');
            }

            resolve(res)
        }).catch(err=>{
            layer.notifiy({
                title: "Network 错误",
                content: err.message,
                icon: 2,
                time: 5000,
            })
            reject(err)
        })
   })
}


// 封装post
/***
 * @params url  地址
 * @params params   表单数据
 * @params isFile  是否有文件上传  有默认值:false
 * 返回值:返回promise对象
*/
export function postReq(url, data){
    // 处理配置项  headers
    let config = {
        headers:{
            "Content-Type":"application/x-www-form-urlencoded"
        }
    }

    /**
     * resolve:执行成功的方法
     * reject:执行失败的方法
    */
    return new Promise((resolve,reject)=>{
        instance.post(url,data,config).then(res=>{
            // 服务端响应成功结果
            if(res.data.code == 401) {
                layer.notifiy({
                    title: "错误",
                    content: "身份过期，请重新登录！",
                    icon: 2,
                    time: 3000,
                })

                // 身份过期跳转到登录页面
                router.push('/login');
            }

            resolve(res)
        }).catch(err=>{
            layer.notifiy({
                title: "Network 错误",
                content: err.message,
                icon: 2,
                time: 5000,
            })
            reject(err)
        })
    })
}


// 封装post
/***
 * @params url  地址
 * @params params   表单数据
 * @params isFile  是否有文件上传  有默认值:false
 * 返回值:返回promise对象
*/
export function postReqWithToken(url, data){
    // 处理配置项  headers
    let config = {
        headers:{
            "Content-Type":"application/x-www-form-urlencoded",
            'token': localStorage.getItem('token')
        }
    }
 
    /**
     * resolve:执行成功的方法
     * reject:执行失败的方法
    */
    return new Promise((resolve,reject)=>{
        instance.post(url,data,config).then(res=>{
            // 服务端响应成功结果
            if(res.data.code == 401) {
                layer.notifiy({
                    title: "错误",
                    content: "身份过期，请重新登录！",
                    icon: 2,
                    time: 3000,
                })

                // 身份过期跳转到登录页面
                router.push('/login');
            }

            resolve(res)
        }).catch(err=>{
            layer.notifiy({
                title: "Network 错误",
                content: err.message,
                icon: 2,
                time: 5000,
            })
            reject(err)
        })
    })
}
